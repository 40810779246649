import * as Types from './contentfulTypes';

import gql from 'graphql-tag';
import * as ApolloReactCommon from 'react-apollo';
export const AssetUrlFragmentDoc = gql`
    fragment AssetUrl on Asset {
  url
}
    `;
export const MarketingContentFieldsFragmentDoc = gql`
    fragment MarketingContentFields on MarketingContent {
  title
  description
  callToActionLabel
  callToActionLink
  visual {
    ...AssetUrl
    description
    width
    height
  }
  visualAlternativeText
}
    ${AssetUrlFragmentDoc}`;
export const GoalsValuePropsFieldsFragmentDoc = gql`
    fragment GoalsValuePropsFields on LoggedOutHomePageContent {
  goalsValuePropsTitle
  goalsValuePropOpen1
  goalsValuePropClose1
  goalsValuePropOpen2
  goalsValuePropClose2
  goalsValuePropOpen3
  goalsValuePropClose3
  goalsValuePropOpen4
  goalsValuePropClose4
}
    `;
export const ImageWithMetadataFieldsFragmentDoc = gql`
    fragment ImageWithMetadataFields on ImageWithMetadata {
  relationSlug
  image {
    url
  }
  imageAltText
}
    `;
export const C4BFieldsFragmentDoc = gql`
    fragment C4BFields on LoggedOutHomePageContent {
  c4BTitle
  c4BText
  c4BEnterpriseCtaText
  c4BPartnersLogosCollection {
    items {
      ...ImageWithMetadataFields
    }
  }
}
    ${ImageWithMetadataFieldsFragmentDoc}`;
export const TestimonialsFieldsFragmentDoc = gql`
    fragment TestimonialsFields on UniversityProgramTestimonial {
  authorName
  authorOrganization
  authorTitle
  image {
    url
  }
  text
}
    `;
export const ValuePropsFieldsFragmentDoc = gql`
    fragment ValuePropsFields on LoggedOutHomePageContent {
  valuePropsTitle
  valuePropTopQuality {
    ...MarketingContentFields
  }
  valuePropAccessible {
    ...MarketingContentFields
  }
  valuePropAppliedLearning {
    ...MarketingContentFields
  }
  valuePropCertificates {
    ...MarketingContentFields
  }
}
    ${MarketingContentFieldsFragmentDoc}`;
export const CarouselMetadataFieldsFragmentDoc = gql`
    fragment CarouselMetadataFields on LoggedOutHomePageCarouselMetadata {
  slug
  sectionTitle
  title
  subtitle
  cardFooterLabel
  callToActionLabel
  callToActionLink
}
    `;
export const MerchandisingHeroModuleFieldsFragmentDoc = gql`
    fragment MerchandisingHeroModuleFields on MerchandisingHeroModule {
  sys {
    id
  }
  region
  headline
  description
  shortDescription
  ctaText
  ctaTarget
  secondaryCtaText
  secondaryCtaTarget
  image {
    url
    description
  }
  imageTablet {
    url
    description
  }
  imageMobile {
    url
    description
  }
  backgroundColor
  eyebrowImage {
    url
    description
  }
}
    `;
export const LoggedOutHomePageContentFieldsFragmentDoc = gql`
    fragment LoggedOutHomePageContentFields on LoggedOutHomePageContent {
  regionSlug
  promoBannerId
  promoBannerText
  hero {
    ...MarketingContentFields
  }
  heroModuleBackgroundColor
  ...GoalsValuePropsFields
  outcomes {
    ...MarketingContentFields
  }
  outcomesC4BCtaText
  outcomesC4BCtaLink
  outcomesFootnote
  ...C4BFields
  partnerLogosTitle
  partnerLogosCollection {
    items {
      ...ImageWithMetadataFields
    }
  }
  testimonialsTitle
  testimonialsCollection {
    items {
      ...TestimonialsFields
    }
  }
  ...ValuePropsFields
  certificateCarouselMetadata {
    ...CarouselMetadataFields
  }
  degreeCarouselMetadata {
    ...CarouselMetadataFields
  }
  s18NCarouselMetadata {
    ...CarouselMetadataFields
  }
  coursesCarouselMetadata {
    ...CarouselMetadataFields
  }
  mastertrackCarouselMetadata {
    ...CarouselMetadataFields
  }
  universityCertificatesCarouselMetadata {
    ...CarouselMetadataFields
  }
  heroSectionPartnerLogosCollection {
    items {
      ...ImageWithMetadataFields
    }
  }
  carouselsMetadataCollection {
    items {
      ...CarouselMetadataFields
    }
  }
  regionalHeroModulesCollection {
    items {
      ...MerchandisingHeroModuleFields
    }
  }
}
    ${MarketingContentFieldsFragmentDoc}
${GoalsValuePropsFieldsFragmentDoc}
${C4BFieldsFragmentDoc}
${ImageWithMetadataFieldsFragmentDoc}
${TestimonialsFieldsFragmentDoc}
${ValuePropsFieldsFragmentDoc}
${CarouselMetadataFieldsFragmentDoc}
${MerchandisingHeroModuleFieldsFragmentDoc}`;
export const GetLoggedOutHomePageContentDocument = gql`
    query getLoggedOutHomePageContent($regionSlug: String!, $preview: Boolean!, $isUsContent: Boolean!, $locale: String!) {
  regionalContent: loggedOutHomePageContentCollection(
    preview: $preview
    where: {regionSlug: $regionSlug}
    limit: 1
    locale: $locale
  ) @skip(if: $isUsContent) {
    items {
      ...LoggedOutHomePageContentFields
    }
  }
  usContent: loggedOutHomePageContentCollection(
    preview: $preview
    where: {regionSlug: "us"}
    limit: 1
    locale: $locale
  ) {
    items {
      ...LoggedOutHomePageContentFields
    }
  }
}
    ${LoggedOutHomePageContentFieldsFragmentDoc}`;
export type GetLoggedOutHomePageContentQueryResult = ApolloReactCommon.QueryResult<Types.GetLoggedOutHomePageContentQuery, Types.GetLoggedOutHomePageContentQueryVariables>;